import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "「サイトの会員登録増加」に効果が出た施策の話",
  "date": "2018-04-06T07:44:39.000Z",
  "slug": "entry/2018/04/06/164439",
  "tags": ["medley"],
  "hero": "./2018_04_06.png",
  "heroAlt": "会員数増加"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部で医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当している田村です。
メドレー開発本部で行われている勉強会「TechLunch」で、ジョブメドレーについて「求人サイトでやって良かった会員登録施策」というタイトルでお話させていただきました。インターネットで検索するとこういう内容はたくさん出てきますが、そのうちの一つとして、参考にしていただければ幸いです。`}</p>
    <h1>{`背景`}</h1>
    <p>{`医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」は、創業当初（2009 年）にリリースし会社と共に進化し続けてきたメドレーで最も歴史のあるサービスです。リリース開始から 9 年ほど経ちますが、個人・事業所にとって使いやすく愛されるサービスとなるよう、日々改善を続けています。その中でも、サービスの成長に重要な要素の 1 つである「ユーザの獲得」に向けた施策は、とても大切にしています。昨年に実施した中で「サイトの会員登録増加に効果が出た施策」について、社内の他サービスにとってもノウハウとなればと、少しだけ紹介させていただきしました。`}</p>
    <h1>{`AB テストによる改善`}</h1>
    <p>{`まずはじめに、既存のサイト内で改善できる点を洗い出して優先順位を付けて費用対効果が高そうなものから着手しました。実際に以下の 2 つを AB テストを行い改善しました。`}</p>
    <ul>
      <li parentName="ul">{`LP の改善`}
        <ul parentName="li">
          <li parentName="ul">{`デザイン変更`}</li>
          <li parentName="ul">{`入力フォームの変更`}</li>
        </ul>
      </li>
      <li parentName="ul">{`会員登録バナーの変更`}</li>
    </ul>
    <h2>{`LP の改善`}</h2>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521801430519_001.png",
        "alt": null
      }}></img></p>
    <p><strong parentName="p">{`BEFORE / AFTER の変更点`}</strong></p>
    <ul>
      <li parentName="ul">{`ユーザの満足度や求人の特徴を強調`}</li>
      <li parentName="ul">{`メリハリを付け、より直感的にわかりやすく`}</li>
      <li parentName="ul">{`職種をイメージしやすいビジュアルに変更`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521801447861_002.png",
        "alt": null
      }}></img></p>
    <p><strong parentName="p">{`BEFORE / AFTER の変更点`}</strong></p>
    <ul>
      <li parentName="ul">{`全ての入力項目のある長い 1 ステップ形式から入力項目を短く分割して 5 ステップ形式に変更`}</li>
      <li parentName="ul">{`ユーザがゴールイメージができるように現在のステップを表示`}</li>
      <li parentName="ul">{`入力しやすいように各項目を大きく表示`}</li>
      <li parentName="ul">{`各ステップへの移動がしやすいようにスクロールしなくても「つぎへ」「もどる」ボタンが押せるようなボタン配置`}</li>
      <li parentName="ul">{`サクサク入力できるように各ステップへの移動でページ遷移をさせない`}</li>
    </ul>
    <p><strong parentName="p">{`どのくらい効果があったのか`}</strong></p>
    <p>{`ビジュアルや訴求内容のブラッシュアップ、そしてユーザビリティを改善したことで会員登録数が約 1.2 倍という結果になりました。1.2 倍で少ないと思うかもしれませんが、サイトの規模が大きくなるほど、`}<strong parentName="p">{`ちょっとした改善を積み上げていくことが重要`}</strong>{`だと考えています。`}</p>
    <h2>{`会員登録バナーの変更`}</h2>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521801467092_003.png",
        "alt": null
      }}></img></p>
    <p><strong parentName="p">{`BEFORE / AFTER の変更点`}</strong></p>
    <ul>
      <li parentName="ul">{`ユーザがイメージしやすい訴求内容に`}</li>
      <li parentName="ul">{`訴求内容を絞り、ユーザが内容をすぐ把握できるようにビジュアルを追加`}</li>
      <li parentName="ul">{`サイトの配色に合ったバナーデザイン`}</li>
    </ul>
    <p><strong parentName="p">{`どのくらい効果があったのか`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521802733296_006.png",
        "alt": null
      }}></img></p>
    <p>{`バナーの訴求内容とデザインを変更したことで、CVR が約 2 倍という結果になりました。訴求内容を多く見せることも大事ですが、`}<strong parentName="p">{`ユーザがバナーを見て内容をすぐに理解できることを重視`}</strong>{`しています。`}</p>
    <h1>{`ユーザの行動を分析して施策を立てる`}</h1>
    <p>{`ジョブメドレーには、会員登録をしていないユーザが気になった求人を保存しておくことができる「キープ機能」があります。求人の保存期限は 2 週間でこの期間内に会員登録をすると保存期限の上限がなくなり、気になった求人をずっと保存しておくことができます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521801722224_004.png",
        "alt": null
      }}></img></p>
    <p>{`施策のきっかけは、ある調査でユーザが積極的にキープ機能を活用していることがわかったことです。このキープ機能を利用しているユーザに保存期間に関する会員登録のメリットを提示すれば、会員登録してくれるのでは？という仮説を持ち、施策を実施することになりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://d2mxuefqeaa7sj.cloudfront.net/s_2F2F8059CA09971E07EAC1AC1B6FD38AA8BB29DC86D47D526F5E89985961A37B_1521801733472_005.png",
        "alt": null
      }}></img></p>
    <p><strong parentName="p">{`BEFORE / AFTER の変更点`}</strong></p>
    <ul>
      <li parentName="ul">{`キープボタンをクリックした際、モーダルを表示`}</li>
      <li parentName="ul">{`保存期間は 2 週間で会員登録すると期間を越えて利用できます！と表記`}</li>
      <li parentName="ul">{`会員登録ボタンを設置`}</li>
    </ul>
    <p><strong parentName="p">{`どのくらい効果があったのか`}</strong></p>
    <p>{`具体的な数値は公表できませんが、リリース当初の会員登録数と比較すると、最近ではおよそ 10 倍程度の結果となりました。ユーザにしてほしい行動・適切な導線改善などから見えてくる施策があるので、積極的に`}<strong parentName="p">{`ユーザの行動を分析し施策を立てて実行することが重要`}</strong>{`だと言えます。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`いかがでしたでしょうか。今回ご紹介した内容の他にも、ジョブメドレーでは、様々な改善を日々続けています。こうした取り組みを通じて、AB テストでちょっとした改善を積み上げていくこと、ユーザの行動を分析し施策を立てて実行すること、そして地道な改善を積み上げていくことがサービスの成長には不可欠だと実感しています。こうした地道な取り組みに励むエンジニアの方は少なくないと思いますが、私たちが実施している施策事例が、皆様の参考となれば幸いです。`}</p>
    <p>{`今後も「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」を会社と共に成長し続けるサービスにしていきたいと思い、日々奮闘していきたいと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「ジョブメドレー」の他にも、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`ちょっと興味があるという方も、ぜひお気軽にご連絡ください！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      